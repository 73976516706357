.worksContainer {
  position: relative;

  width: 100vw;

  padding: 5vw 0vw;

  display: flex;
  flex-flow: column;
}

.worksContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/bgImg-workouts.jpg');
}

.worksContainer .container {
  z-index: 2;

  max-width: 90%;

  margin: auto;
  padding: 2vw;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  flex-flow: column;
}

.worksContainer .container .videoCont {
  position: relative;

  margin: auto;
}

/* -------------------------------------- */

.worksContainer .container .videoCont span {
  height: 2px;

  margin: 0;

  background-color: #FFF;

  border-radius: 2px;
}

.worksContainer .container .videoCont .sup {
  position: absolute;
  top: 0;
  left: -1%;

  width: 101%;
}

.worksContainer .container .videoCont .rig {
  position: absolute;
  top: -1%;
  right: 0%;

  width: 2px;
  height: 101%;
}

.worksContainer .container .videoCont .infer {
  position: absolute;
  bottom: 0;
  left: 0%;

  width: 101%;
}

.worksContainer .container .videoCont .left {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 2px;
  height: 101%;
}

/* -------------------------------------- */

.worksContainer .container .videoCont h2 {
  z-index: 10;

  margin: auto;
  padding: 1vw;

  position: absolute;
  top: -25px;
  left: 20%;
  right: 20%;

  background-color: rgba(0, 0, 0, 0.9);
  color: #FFF;
  text-align: center;
  font-size: 1vw;
  line-height: 1vw;
}

.worksContainer .container .videoCont .videos {
  z-index: 10;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin: 3vw 1vw 1vw;
}

.worksContainer .container .videoCont .videos .videoCont {
  width: 18vw;
  height: 16vw;

  min-width: 300px;
  min-height: 260px;

  margin: 2vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  cursor: pointer;
}

.worksContainer .container .videoCont .videos .videoCont .title {
  color: #FFF;
  font-size: .9vw;
  text-align: center;
  text-transform: uppercase;

  width: 70%;

  padding: .2vw .5vw;

  background-color: rgba(0, 0, 0, 0.8);
}

.worksContainer .container .videoCont .videos .videoCont .minImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  width: 100%;
  height: 80%;
}

.worksContainer .container .videoCont .videos .videoCont .img-1 {
  background-image: url('../../images/memories/1.png');
}
.worksContainer .container .videoCont .videos .videoCont .img-2 {
  background-image: url('../../images/memories/2.png');
}
.worksContainer .container .videoCont .videos .videoCont .img-3 {
  background-image: url('../../images/memories/3.png');
}
.worksContainer .container .videoCont .videos .videoCont .img-4 {
  background-image: url('../../images/memories/4.png');
}
.worksContainer .container .videoCont .videos .videoCont .img-5 {
  background-image: url('../../images/memories/5.png');
}

@media screen and (max-width: 768px) {
  .worksContainer {
    padding: 20vw 0vw 5vw;
  }

  .worksContainer .container .videoCont h2 {
    z-index: 10;

    margin: auto;
    padding: 2vw;

    position: absolute;
    top: -55px;
    left: 5%;
    right: 5%;

    background-color: rgba(0, 0, 0, 0.95);
    color: #FFF;
    text-align: center;
    font-size: 4vw;
    line-height: 4vw;
  }

  .worksContainer .container .videoCont .videos .videoCont .title {
    width: 90%;

    font-size: 4vw;

    margin: 0 auto;

    padding: .2vw .5vw;
  }

  .worksContainer .container .videoCont .videos .videoCont {
    width: 58vw;
    height: 50vw;

    min-width: 250px;
    min-height: 210px;

    margin: 2vw;
  }

  .worksContainer .container .videoCont .videos .videoCont .minImg {
    width: 100%;
    height: 100%;

    min-height: 150px;
  }
}