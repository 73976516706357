.trainersContainer {
  position: relative;

  width: 100vw;
  padding: 3vw 0vw;

  display: flex;
  flex-flow: column;
}

.trainersContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/trainers/bgImg-trainers.png');
}

.trainersContainer .container {
  z-index: 2;

  width: 80vw;
  /* height: calc(80vw / 2.119); */

  margin: auto;
  padding: 2vw;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  flex-flow: column;
}

.trainersContainer .container .title {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0vw auto 2vw;
}

.trainersContainer .container .iconAgenda {
  width: 6vw;
  height: calc(6vw / 3.46666);

  margin: auto 1vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/agenda/icon-reebok.png');
}

.trainersContainer .container h2 {
  color: #FFF;
  font-size: 2vw;
  line-height: 2vw;
  font-weight: 100;
  font-family: var(--font-NeuePlak-Ext-Regular);

  margin: auto 0.5vw;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.trainersContainer .container h2 span {
  color: #FFF;
  font-size: 2vw;
  font-weight: 100;
  font-family: var(--font-NeuePlak-Ext-Black);

  margin: auto .7vw;
}

/* ====================================== */

.trainersContainer .container .trainerInfo {
  z-index: 2;

  width: 90%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.trainersContainer .container .trainerInfo .trainerCont {
  width: 13vw;
  height: calc(13vw);

  min-width: 220px;
  min-height: 270px;

  margin: 2vw .5vw;

  text-decoration: none;
  font-size: 1.2vw;
  color: #FFF;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}

.trainersContainer .container .trainerInfo .trainerCont .ImgTrainer {
  width: 90%;
  height: 70%;
  min-height: 150px;

  background-repeat: no-repeat;

  margin: auto;
}

/* ---------------------------------------------- */

.trainersContainer .container .trainerInfo .trainerCont div[class^="Les"] {
  background-position: center;
  background-image: url('../../images/trainers/les-mills.png');
  background-size: 220%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Isa"] {
  background-position: center;
  background-image: url('../../images/trainers/Isa-strada.png');
  background-size: 140%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Diana"] {
  background-position: top;
  background-image: url('../../images/trainers/Diana-Motlak.png');
  background-size: 170%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Silvana"] {
  background-position: center;
  background-image: url('../../images/trainers/silvana-hurtado.png');
  background-size: 160%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Issa"] {
  background-position: center;
  background-image: url('../../images/trainers/Issa-vasquez.png');
  background-size: 150%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Cheli"] {
  background-position: 10% 10%;
  background-image: url('../../images/trainers/cheli.png');
  background-size: 180%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Tatiana"] {
  background-position: 55% 15%;
  background-image: url('../../images/trainers/tatiana-ussa.png');
  background-size: 180%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Smartfit"] {
  background-position: 55% 15%;
  background-image: url('../../images/trainers/smartfit-zona-prieta.png');
  background-size: 120%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Manuela"] {
  background-position: 30% 70%;
  background-image: url('../../images/trainers/Manu-uribe.png');
  background-size: 150%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Ricki"] {
  background-position: 40% 10%;
  background-image: url('../../images/trainers/ricki-alvarez.png');
  background-size: 150%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Natalia"] {
  background-position: 80% 20%;
  background-image: url('../../images/trainers/Natalia-castillo.png');
  background-size: 140%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Paula"] {
  background-position: 30% 0%;
  background-image: url('../../images/trainers/Paula-suarez.png');
  background-size: 140%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Caro"] {
  background-position: 40% 10%;
  background-image: url('../../images/trainers/caro-mejia.png');
  background-size: 180%;
}

.trainersContainer .container .trainerInfo .trainerCont div[class^="Mia"] {
  background-position: 80% 20%;
  background-image: url('../../images/trainers/mia-arango.png');
  background-size: 180%;
}

/* ---------------------------------------------- */

.trainersContainer .container .trainerInfo .trainerCont .trainerName {
  font-size: 1.3vw;
  color: #FFF;
  font-family: var(--font-NeuePlak-Ext-Black);
  text-align: center;

  margin: auto;
}

.trainersContainer .container .trainerInfo .trainerCont .nick {
  font-size: 1.1vw;
  color: #FFF;
  font-family: var(--font-NeuePlak-Regular);
  font-weight: 100;
  text-align: center;

  margin: auto;
}

/* ============================================================= */

@media screen and (max-width: 768px) {

  .trainersContainer .container .iconAgenda {
    width: 13vw;
    height: calc(13vw / 3.46666);

    margin: auto 1vw;
  }

  .trainersContainer .container h2 {
    font-size: 3vw;
    line-height: 3vw;

    margin: auto 0.5vw;
  }

  .trainersContainer .container h2 span {
    font-size: 3vw;
    font-weight: 100;

    margin: auto .7vw;
  }

  /* --------------------------------------------- */

  .trainersContainer .container {
    width: 90vw;
    margin: auto;
    padding: 2vw;

    background-color: rgba(0, 0, 0, 0.8);
  }

  .trainersContainer .container .trainerInfo {
    z-index: 2;

    width: 100%;

    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .trainersContainer .container .trainerInfo .trainerCont {
    width: 10vw;
    height: calc(10vw);

    min-width: 40vw;
    min-height: 260px;

    margin: 2vw .5vw;
    text-decoration: none;
    font-size: 1.2vw;
    color: #FFF;
  }

  .trainersContainer .container .trainerInfo .trainerCont .ImgTrainer {
    width: 90%;
    height: 70%;
    min-height: 200px;
  }

  .trainersContainer .container .trainerInfo .trainerCont div[class^="Smartfit"] {
    background-position: 55% 15%;
    background-image: url('../../images/trainers/smartfit-zona-prieta.png');
    background-size: 180%;
  }

  .trainersContainer .container .trainerInfo .trainerCont .trainerName {
    font-size: 4.3vw;
    line-height: 4.3vw;
    color: #FFF;
    font-family: var(--font-NeuePlak-Ext-Black);
    text-align: center;

    margin: 0px auto;
  }

  .trainersContainer .container .trainerInfo .trainerCont .nick {
    font-size: 3.1vw;
    color: #FFF;
    font-family: var(--font-NeuePlak-Regular);
    font-weight: 100;
    text-align: center;

    margin: auto;
  }
}