.checkBoxContainer {
  width: 80%;
  margin: 10px auto;
  padding: 10px;

  display: flex;
  flex-flow: row;
}

.checkBoxContainer .checkBox {
  display: none;
  cursor: pointer;
}

.checkBoxContainer .customCheckBox {
  cursor: pointer;

  text-decoration: none;
  font-family: var(--font-COLGATEREADY);
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #FFF;
  text-align: start;

  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.checkBoxContainer .customCheckBox::before {
  content:'X';

  background:transparent;
  border: 1px solid #FFF;
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 25px;
  min-width: 25px;
  width: 25px;

  margin: auto 10px auto 0px;

  text-align:center;
  line-height: 16px;
  font-size: 16px;
  color: #AAA;
}

input[type=checkbox]:checked + label:before {
  content: '✔';

  color: var(--color-light-green);
  border: 1px solid var(--color-light-green);
  background-color: #FFF;
}

@media screen and (max-width: 1024px) {
  .checkBoxContainer {
    width: 90%;
    margin: 5px auto;
  }

  .checkBoxContainer .customCheckBox {
    font-size: 14px;
  }
}
