@import '../../pages/standar.css';

.agendaCont {
  z-index: 2;

  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.agendaCont .imgAgenda {
  width: 80vw;
  height: calc(80vw / 2.119);

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 768px) {
  .agendaCont .imgAgenda {
    width: 60vw;
    height: calc(60vw / 0.62);

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 640px) {
  .agendaCont .imgAgenda {
    width: 80vw;
    height: calc(80vw / 0.62);

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
