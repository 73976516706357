.coverPage {
  background: url('../../images/pcPosRegistroBackground.png') no-repeat;
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.coverPageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.699);
  height: 18vh;
}

.coverPageHeader img {
  height: 10vh;
}

.coverPageContent {
  display: flex;
  flex-direction: row;
  flex: 100;
}

.coverPageLeft {
  display: flex;
  background: url('../../images/pcPosregistroImg.png') no-repeat center center;
  background-size: contain;
  flex: 11;
}

.coverPageRight {
  display: flex;
  flex: 5;
  justify-content: flex-start;
  align-items: center;
}

.coverPageMessage {
  color: white;
  width: 85%;
  font-size: 2vw;
  text-align: center;
}

.coverPageTitle {
  font-size: 1.7vw;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 0.5vh;
  font-family: var(--font-NeuePlak-Text-Regular);
}

.coverPageMsg {
  margin-top: 3vh;
  font-size: 1.5vw;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 3vh;
  font-family: var(--font-NeuePlak-Text-Regular);
}

.agendaBtn {
  margin: 20px auto;
  padding: 10px 20px;

  font-size: 1.7vw;
  font-family: var(--font-NeuePlak-Text-Regular);
  line-height: 1.7vw;
  color: #FFF;

  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.699);

  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .coverPageContent {
    flex-direction: column;
  }

  .coverPageLeft {
    flex: 5;
  }

  .coverPageRight {
    flex: 5;
    align-items: flex-start;
    justify-content: center;
  }

  .coverPageTitle {
    font-size: 1rem;
  }

  .coverPageMsg {
    font-size: 1rem;
  }

  .agendaBtn {
    font-size: 4.7vw;
    line-height: 4.7vw;
  }
}
