.room {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: max-content;
}

.room .sections {
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  background-color: steelblue;
}

/* =============================== */

.room .roomContent .roomLeft .roomVideoContainer {
  width: 60vw;
  height: calc(60vw / 1.77777);

  margin: auto;

  display: flex;
}

.room .roomContent .roomLeft .roomVideoContainer .iframeCont {
  width: 100%;
  height: 100%;

  margin: auto;

  display: flex;
}

.room .roomContent .roomLeft .roomVideoContainer .iframeCont iframe {
  width: 100%;
  height: 100%;
  border: 0;

  margin: auto;
}

/* ======================================================== */
/* ======================================================== */

.room .roomContent .roomRight {
  width: 30%;
  height: 90vh;
  min-width: 300px;

  display: flex;
  flex-flow: column;

  margin: auto;
}

.room .roomContent .roomRight .roomChatContainer {
  height: 50%;
  width: 80%;
  padding: 1vw;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;

  margin: auto;
}

.room .roomContent .roomRight .roomChatContainer .chat {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatBuffer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2.5%;
  margin: 0px auto 10px auto;
  width: 95%;
  height: 100%;

  scrollbar-width: thin;
  scrollbar-color: #002735 transparent;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatBuffer::-webkit-scrollbar-track {
  background: transparent;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatCard {
  width: 80%;
  padding: 20px;
  background-color: var(--color-light-blue);
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 1vh;
  color: #FFF;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatOwnCard {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px 20px;
  background-color: var(--color-dark-blue);
  border-radius: 10px 10px 0px 10px;
  margin-bottom: 1vh;
  color: white;
  align-self: flex-end;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatOwnCard p {
  text-align: end;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatUsername {
  margin: 0px;
  font-size: 12px;
  font-weight: 300;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatMessage {
  overflow-wrap: anywhere;
  font-size: 1rem;
  margin: 5px 0px;
}

.room .roomContent .roomRight .roomChatContainer .chat .response {
  border-top: 1px solid #FFF;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatForm {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatInput {
  position: relative;

  width: 100%;
  height: 30px;
  border-radius: 50px;
  outline: none;
  border: solid 1px  var(--color-light-blue);
  padding: 5px 40px 5px 20px;

  color: var(--color-dark-blue);
  font-size: 18px;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatInput::placeholder {
  color: #CCC;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatInput:focus {
  border: solid 1px  var(--color-dark-blue);
}

.room .roomContent .roomRight .roomChatContainer .chat .chatInputButton {
  position: absolute;
  top: 15%;
  right: 2%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50px;
  cursor: pointer;
  background-color: var(--color-light-blue);
  border: none;
  font-weight: 900;
  font-size: 16px;
  color: white;
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: none;
}

.room .roomContent .roomRight .roomChatContainer .chat .chatInputButton:hover {
  background-color: var(--color-dark-blue);
}

/* ====================================================== */

.room .roomContent .roomRight .gamecontainer {
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.room .roomContent .roomRight .gamecontainer .chartContainer {
  background-color: rgba(255, 255, 255, 0.95);

  width: 90%;

  margin: auto;
  padding: 10px;

  border-radius: 10px;

  display: flex;
}

.room .roomContent .roomRight .gamecontainer .chartContainer #myChart {
  margin: auto;
}

.room .roomContent .roomRight .gamecontainer .roomTriviaContainer {
  width: 100%;

  margin: 20px auto;

  display: flex;
}

/* ---------------------------------------------------------- */

.room .whatsappCont {
  z-index: 10;

  position: fixed;
  right: 0.4%;
  bottom: 4vw;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.room .whatsappCont .imgWhatsapp {
  width: 60px;
  height: 60px;

  background-image: url('../../images/whatsColor.png');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;

  transition: .2s;
}
.room .whatsappCont .imgWhatsapp:hover {
  transform: scale(1.05);
}

.room .whatsappCont .text {
  font-size: .8vw;
  color: #FFF;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: .3vw;
}

@media screen and (max-width: 768px) {
  .room .whatsappCont .text {
    font-size: 2vw;
    color: #FFF;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
