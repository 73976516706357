.bodyModaMemories {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .7);

  display: flex;

  z-index: 3000;
}

.bodyModaMemories .closeModal {
  position: absolute;
  top: 3%;
  right: 3%;

  width: 30px;
  height: 30px;

  border: none;
  border-radius: 100px;
  outline: none;

  background-color: transparent;
  color: #FFF;
  font-size: 3vw;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  z-index: 200;
}

.bodyModaMemories .modalCont {
  position: relative;

  width: 70vw;
  height: calc(70vw / 1.7777);

  margin: auto;

  background-color: rgba(0, 0, 0, .8);
  border-radius: 0px;

  display: flex;
  flex-flow: column;

  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .bodyModaMemories .closeModal {
    background-color: transparent;
    color: #FFF;
    font-size: 6vw;
  }
}
