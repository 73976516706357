.sorteoContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
}

.sorteoContainer .graphContainer {
  position: relative;

  width: 100%;

  margin: auto;

  display: flex;
}

.sorteoContainer .graphContainer .bgImg {
  margin: auto;

  width: 80vw;
  height: calc(80vw / 1.777);
}

.sorteoContainer .graphContainer .ruleta {
  position: absolute;
  top: 4%;
  left: 15%;

  width: 40vw;
  height: 40vw;

  border-radius: 50vw;
  border: 3px solid #FFF;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 97%;
  background-image: url('../../images/ruleta.png');
  background-color: rgba(0, 0, 0, 0.699);

  display: flex;
}

.sorteoContainer .graphContainer .ruleta-rotate {
  position: absolute;
  top: 4%;
  left: 20%;

  width: 40vw;
  height: 40vw;

  border-radius: 100vw;
  border: 3px solid #FFF;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 95%;
  background-image: url('../../images/ruleta.png');
  background-color: rgba(0, 0, 0, 0.699);

  display: flex;

  animation: rotation .6s infinite linear;
}

.sorteoContainer .graphContainer .center {
  position: absolute;
  top: 44%;
  left: 5%;

  width: 8vw;
  height: 4vw;

  margin: auto;

  background-color: rgba(0, 0, 0, 0.96);
}

.sorteoContainer .graphContainer .center h1 {
  width: 100%;

  text-align: center;
  font-size: 3vw;
  line-height: 3.5vw;

  margin: 0;

  color: #FFF;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* ============================ */

.sorteoContainer .graphContainer .nameSection {
  position: absolute;
  top: 45%;
  right: 5%;

  width: 30vw;

  margin: auto;
  padding: 10px 20px;

  background-color: rgba(0, 0, 0, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;
}

.sorteoContainer .graphContainer .nameSection p {
  line-height: 2.7vw;
  font-size: 2.7vw;
  color: #FFF;
  text-align: center;

  margin: 0;
  padding: 0;
}

/* ========================================== */

.sorteoContainer .controlsContainer {
  width: 90%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.sorteoContainer .controlsContainer input {
  border: none;
  outline: none;
  border-radius: 10px;

  margin: auto;
  padding: 10px 20px;

  color: #333;
  font-size: 18px;
  box-shadow: 3px 3px 10px #888;
}

.sorteoContainer .controlsContainer .btnActions {
  padding: 10px 20px;
  margin: 30px auto;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #002735;
  cursor: pointer;

  font-size: 18px;
  color: #FFF;
}

.sorteoContainer .controlsContainer .btnActions:hover {
  background-color: #004775;
  color: #FFF;
}