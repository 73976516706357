@import './pages/standar.css';

html, * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-light-lila) var(--color-dark-lila);
  outline: none;
  font-family: Neue-Plak-Text-Regular;
  scroll-behavior: smooth;
}

/* width and height */
*::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: var(--color-dark-lila);
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--color-light-lila);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-light-blue);
}

body {
  margin: 0;
  padding: 0;
  background-color: #FFF;

  font-family: -apple-system, var(--Neue-Plak-Text-Regular), 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  min-height: 100vh;

  overflow-x: hidden;
  overflow-y: auto;
}

body #root {
  width: 100vw;
  min-height: 100vh;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.bold {
  font-weight: 900;
}

.semiBold {
  font-weight: 600;
}

h4, h2, p {
  margin: 0;
}
