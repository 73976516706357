.videoContMem {
  position: relative;

  width: 90%;
  height: 90%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContMem h1 {
  color: #FFF;
}

.videoContMem .iframeCont {
  margin: auto;

  width: 99%;
  height: 99%;

  display: flex;
}

.videoContMem .iframeCont iframe {
  width: 99%;
  height: 99%;

  margin: auto;
}

/* ----------------------------------- */

.videoContMem span {
  height: 2px;

  margin: 0;

  background-color: #FFF;

  border-radius: 2px;
}

.videoContMem .sup {
  position: absolute;
  top: 0;
  left: -1%;

  width: 101%;
}

.videoContMem .rig {
  position: absolute;
  top: -1%;
  right: 0%;

  width: 2px;
  height: 101%;
}

.videoContMem .infer {
  position: absolute;
  bottom: 0;
  left: 0%;

  width: 101%;
}

.videoContMem .left {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 2px;
  height: 101%;
}

/* ----------------------------------- */