.bodyModal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .7);

  display: flex;

  z-index: 3000;
}

.bodyModal .closeModal {
  position: absolute;
  top: 3%;
  right: 3%;

  width: 30px;
  height: 30px;

  border: none;
  border-radius: 100px;
  outline: none;

  background-color: var(--color-light-blue);
  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  z-index: 200;
}

.bodyModal .modalCont {
  position: relative;

  width: 80vw;
  height: calc(80vw / 2.119);

  margin: auto;

  background-color: rgba(255, 255, 255, .1);
  border-radius: 0px;

  display: flex;
  flex-flow: column;

  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .bodyModal .modalCont {
    position: relative;

    width: 60vw;
    height: calc(60vw / 0.62);

    overflow-x: auto;

    background-color: rgba(255, 255, 255, .1);
    border-radius: 0px;

    display: flex;
    flex-flow: column;

    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 640px) {
  .bodyModal .modalCont {
    position: relative;

    width: 80vw;
    height: calc(80vw / 0.62);

    overflow-x: auto;

    background-color: rgba(255, 255, 255, .1);
    border-radius: 0px;

    display: flex;
    flex-flow: column;

    overflow-y: auto;
    overflow-x: hidden;
  }
}
