/* Neue Plak FONTS */

@font-face {
  font-family: Neue-Plak-Wide-Regular;
  src: url('./Monotype-Neue-Plak-Wide-Regular.woff');
}

@font-face {
  font-family: Neue-Plak-Wide-Light;
  src: url('./NeuePlak-WideLight.woff');
}

@font-face {
  font-family: Neue-Plak-Wide-ExtraBlack;
  src: url('./NeuePlak-WideExtraBlack.woff');
}

@font-face {
  font-family: Neue-Plak-Wide-Black;
  src: url('./NeuePlak-WideBlack.woff');
}

@font-face {
  font-family: Neue-Plak-Text-Regular;
  src: url('./Neue\ Plak\ Text\ Regular.woff');
}

@font-face {
  font-family: Neue-Plak-Black;
  src: url('./NeuePlak-Black.woff');
}

@font-face {
  font-family: Neue-Plak-Bold;
  src: url('./NeuePlak-Bold.woff');
}

@font-face {
  font-family: Neue-Plak-Light;
  src: url('./NeuePlak-Light.woff');
}

@font-face {
  font-family: Neue-Plak-Regular;
  src: url('./NeuePlak-Regular.woff');
}

@font-face {
  font-family: Neue-Plak-Thin;
  src: url('./NeuePlak-Thin.woff');
}

@font-face {
  font-family: Neue-Plak-Cond-Bold;
  src: url('./NeuePlak-CondBold.woff');
}

@font-face {
  font-family: Neue-Plak-Cond-Light;
  src: url('./NeuePlak-CondLight.woff');
}

@font-face {
  font-family: Neue-Plak-Cond-Thin;
  src: url('./NeuePlak-CondThin.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-Black;
  src: url('./NeuePlak-ExtendedBlack.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-Bold;
  src: url('./NeuePlak-ExtendedBold.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-ExtraBlack;
  src: url('./NeuePlak-ExtendedExtraBlack.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-Light;
  src: url('./NeuePlak-ExtendedLight.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-Regular;
  src: url('./NeuePlak-ExtendedRegular.woff');
}

@font-face {
  font-family: Neue-Plak-Ext-Thin;
  src: url('./NeuePlak-ExtendedThin.woff');
}
