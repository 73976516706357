@import '../components/fonts/fonts.css';

:root {
  --color-light-blue: #6caab8;
  --color-dark-lila: #171F49;
  --color-light-lila: #4B4C7A;

  --font-NeuePlak-Wide-Regular: Neue-Plak-Wide-Regular;
  --font-NeuePlak-Wide-Light: Neue-Plak-Wide-Light;
  --font-NeuePlak-Wide-ExtraBlack: Neue-Plak-Wide-ExtraBlack;
  --font-NeuePlak-Wide-Black: Neue-Plak-Wide-Black;

  --font-NeuePlak-Text-Regular: Neue-Plak-Text-Regular;

  --font-NeuePlak-Black: Neue-Plak-Black;
  --font-NeuePlak-Bold: Neue-Plak-Bold;
  --font-NeuePlak-Light: Neue-Plak-Light;
  --font-NeuePlak-Regular: Neue-Plak-Regular;
  --font-NeuePlak-Thin: Neue-Plak-Thin;

  --font-NeuePlak-Cond-Bold: Neue-Plak-Cond-Bold;
  --font-NeuePlak-Cond-Light: Neue-Plak-Cond-Light;
  --font-NeuePlak-Cond-Thin: Neue-Plak-Cond-Thin;

  --font-NeuePlak-Ext-Black: Neue-Plak-Ext-Black;
  --font-NeuePlak-Ext-Bold: Neue-Plak-Ext-Bold;
  --font-NeuePlak-Ext-ExtraBlack: Neue-Plak-Ext-ExtraBlack;
  --font-NeuePlak-Ext-Light: Neue-Plak-Ext-Light;
  --font-NeuePlak-Ext-Regular: Neue-Plak-Ext-Regular;
  --font-NeuePlak-Ext-Thin: Neue-Plak-Ext-Thin;
}
