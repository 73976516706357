.entranceContainer {
  background: url('../../images/bgImg.png') no-repeat;
  background-size: 100vw 100vh;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
}

.entranceContainer #imgPIXEL {
  display: none;
}

.entranceLeft {
  display: flex;
  flex: 11;
  background: url('../../images/title.png') no-repeat center left;
  background-size: contain;
}

.entranceRight {
  display: flex;
  height: 100vh;
  flex: 5;
  justify-content: flex-start;
  align-items: center;
}

.entranceContent {
  display: flex;
  background-color: #171f49bd;
  color: white;
  min-height: max-content;
  height: 50%;
  width: 80%;
}

.entranceOption {
  display: flex;
  flex-direction: column;
  min-height: min-content;
  justify-content: space-between;
  background-color: #171f49bd;
  color: white;
  height: 50vh;
  width: 80%;
}

.entranceFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: max-content;
  height: 100%;
}

.entranceOption form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh 2vh;
  justify-self: center;
  min-width: 80%;
  padding: 1vh 0.5vh;
  height: 100%;
}

.entranceMenu {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
  align-items: center;
  font-family: var(--font-NeuePlak-Bold);
}

.entrancePageTerm {
  font-family: var(--font-NeuePlak-Text-Regular);
  font-size: 0.7rem;
  line-height: 1;
  margin-left: 1vh;
}

.entrancePageTerm a {
  color: white;
}

.entranceMenuInputTerms {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entranceMenu h4 {
  font-weight: 900;
  font-size: 0.75rem;
}

.entranceMenuSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0% 2.5%;
  width: 45%;
  height: 100%;
  min-height: max-content;
}

.entranceMenuUnselected {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1% 2.5%;
  width: 45%;
  background-color: #5d5f99;
  height: 2rem;
  min-height: max-content;
}

.entranceMenuInputImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5vh;
  width: 2.5vh;
  margin: 1vh;
}

.entranceMenuInputImg * {
  margin: 0;
}

.entranceMenuInput {
  display: flex;
  flex-direction: row;
  border: 1px solid white;
  margin-top: 2vh;
  width: 80%;
  background-color: transparent;
}

.entranceMenuInput input, .entranceMenuInput select {
  border: 0;
  outline: none;
  padding: 0vh 1vh;
  width: 100%;
}

.entranceMenuInputSend {
  margin-top: 2vh;
  cursor: pointer;
  background-color: #5d5f99;
  border: 0px;
  color: white;
  font-weight: 900;
  padding: 1vh 2vh;
}

.entrancePageEmailLabel {
  margin-top: 3vh;
  font-family: var(--font-NeuePlak-Text-Regular);
  font-size: 0.7rem;
  line-height: 1;
  margin-left: 1vh;
}

.supportButton {
  position: absolute;
  top: 2vh;
  right: 2vw;
  color: white;
  background-color: #171f49cc;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  padding: 1vh 2vh;
  box-shadow: 3px 3px 5px rgb(255, 255, 255);
}

@media (max-height: 500px) {
  .entranceOption {
    height: 60vh;
  }
}

@media screen and (max-aspect-ratio: 5/6) {
  .entranceContainer {
    background: url('../../images/mobileBackground.png') no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .entranceLeft {
    display: flex;
    flex: 11;
    background: url('../../images/mobileLeftImg.png') no-repeat center;
    background-size: contain;
    width: 100%;
  }
  .entranceRight {
    display: flex;
    flex: 5;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
  }
}

@media screen and (max-aspect-ratio: 7/6) and (max-width: 600px){
  .entranceContainer {
    background: url('../../images/mobileBackground.png') no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: auto;
  }
  .entranceLeft {
    display: flex;
    height: 45vh;
    background: url('../../images/mobileLeftImg.png') no-repeat center bottom;
    background-size: cover;
    width: 100%;
  }
  .entranceRight {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 55vh;
    width: 100%;
  }
  .entranceOption {
    height: 77%;
    margin-top: 3vh;
  }
  .supportButton {
    top: 1vh;
    right: 1vw;
  }
}