.playerContainer {
  position: relative;

  width: 100vw;
  padding: 5vw 0vw 0vw;

  display: flex;
  flex-flow: column;
}

.playerContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/player/imgBgLive.png');
}

.playerContainer .playerbannerCont {
  position: relative;

  z-index: 2;

  margin: auto auto 1vw auto;
  padding: 2vw 2vw 7vw 2vw;

  background-color: rgba(0, 0, 0, 0.699);

  display: flex;
  flex-flow: column;
}

.playerContainer .playerbannerCont .streamingBorders {
  position: relative;

  display: flex;
}

.playerContainer .playerbannerCont .iframeCont {
  margin: auto;

  width: 64vw;
  height: calc(65vw / 1.777);

  display: flex;
}

.playerContainer .playerbannerCont .iframeCont iframe {
  margin: auto;

  width: 100%;
  height: 100%;
}

/* ------------------------ */

.playerContainer .playerbannerCont span {
  height: 2px;

  margin: 0;

  background-color: #FFF;

  border-radius: 2px;
}

.playerContainer .playerbannerCont .sup {
  position: absolute;
  top: 0;
  left: -1%;

  width: 101%;
}

.playerContainer .playerbannerCont .rig {
  position: absolute;
  top: -1%;
  right: 0%;

  width: 2px;
  height: 101%;
}

.playerContainer .playerbannerCont .infer {
  position: absolute;
  bottom: 0;
  left: 0%;

  width: 101%;
}

.playerContainer .playerbannerCont .left {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 2px;
  height: 101%;
}

/* =============================================== */

.playerContainer .playerbannerCont .bannerCont {
  position: absolute;
  bottom: 1vw;
  left: 2vw;

  width: 64vw;
  height: calc(64vw / 12);

  background-color: #000;
  background-image: url('../../images/player/gif_1\ Smart\ Fit\ .gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------------------------ */

.playerContainer .zoomBtn {
  position: absolute;
  left: 3vw;
  bottom: 8vw;
  z-index: 2;

  width: 10vw;
  height: calc(10vw / 2.24050);

  background-image: url('../../images/player/btnZoom.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

}

.playerContainer .zoomBtn p {
  display: none;
}

/* ===================================================== */


@media screen and (max-width: 768px) {
  .playerContainer {
    width: 100vw;
    padding: 10vw 0vw 5vw;
  }

  .playerContainer .zoomBtn {
    z-index: 1000;

    position: fixed;
    top: 3vw;
    left: 15vw;

    width: 8vw;
    height: calc(8vw);
    background-image: url('../../images/zoom-video-icon.png');

    text-decoration: none;
  }

  .playerContainer .zoomBtn p {
    position: absolute;
    top: 5%;
    right: -210%;

    text-align: center;
    font-size: 12px;
    line-height: 13px;
    color: #FFF;

    width: 70px;
  }

  .playerContainer .playerbannerCont {
    margin: auto auto 1vw auto;
    padding: 2vw 2vw 12vw 2vw;
  }

  .playerContainer .playerbannerCont .iframeCont {
    width: 94vw;
    height: calc(95vw / 1.777);
  }

  .playerContainer .playerbannerCont .bannerCont {
    position: absolute;
    bottom: 1.5vw;
    left: 2vw;

    width: 94vw;
    height: calc(94vw / 12);
  }
}

@media screen and (max-width: 370px) {
  .playerContainer .zoomBtn p {
    display: none;
  }
}