@import '../standar.css';

.agendaContainer {
  position: relative;

  width: 100vw;
  padding: 2vw 0vw;

  display: flex;
  flex-flow: column;
}

.agendaContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/agenda/imgBgAgenda.png');
}

.agendaContainer .container {
  z-index: 2;

  width: 80vw;
  /* height: calc(80vw / 2.119); */

  margin: auto;
  padding: 2vw;

  background-color: rgba(0, 0, 0, 0.699);

  display: flex;
  flex-flow: column;
}

.agendaContainer .container .title {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0vw auto 2vw;
}

.agendaContainer .container .iconAgenda {
  width: 6vw;
  height: calc(6vw / 3.46666);

  margin: auto 1vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/agenda/icon-reebok.png');
}

.agendaContainer .container h2 {
  color: #FFF;
  font-size: 2vw;
  line-height: 2vw;
  font-weight: 100;
  font-family: var(--font-NeuePlak-Ext-Regular);

  margin: auto 0.5vw;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.agendaContainer .container h2 span {
  color: #FFF;
  font-size: 2vw;
  font-weight: 100;
  font-family: var(--font-NeuePlak-Ext-Black);

  margin: auto .7vw;
}

/* ===================================== */

@media screen and (max-width: 768px) {
  .agendaContainer .container {
    width: 60vw;
    /* height: calc(60vw / 0.62); */
  }
}

@media screen and (max-width: 640px) {
  .agendaContainer .container {
    width: 80vw;
    /* height: calc(80vw / 0.62); */
  }
}
