@import '../standar.css';

.storeContainer {
  position: relative;

  width: 100vw;

  display: flex;
}

.storeContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  /* background-image: url('../../images/pcPosRegistroBackground.png'); */
}

.storeContainer .buy {
  z-index: 2;

  margin: 12vw auto auto;
  padding: .5vw 1vw;

  color: #333;
  font-size: 1vw;
  font-weight: 700;
  text-decoration: none;

  background-color: #EEE;
}

.storeContainer .productsContainer {
  z-index: 2;

  width: 70%;

  margin: 10vw auto 2vw auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.storeContainer .productsContainer .productInfo {
  width: 15vw;
  height: 17vw;

  min-width: 250px;
  min-height: 250px;

  background-color: #FFF;

  margin: 1vw;

  display: flex;
  flex-flow: column;

  text-decoration: none;
}

.storeContainer .productsContainer .productInfo .imgProduct {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  width: 15vw;
  height: calc(15vw / 1.4975);

  margin: auto;
}

.storeContainer .productsContainer .productInfo .img-0 {
  background-image: url('../../images/store/0.png');
}

.storeContainer .productsContainer .productInfo .img-1 {
  background-image: url('../../images/store/1.png');
}

.storeContainer .productsContainer .productInfo .img-2 {
  background-image: url('../../images/store/2.png');
}

.storeContainer .productsContainer .productInfo .img-3 {
  background-image: url('../../images/store/3.png');
}

.storeContainer .productsContainer .productInfo .img-4 {
  background-image: url('../../images/store/4.png');
}

.storeContainer .productsContainer .productInfo .img-5 {
  background-image: url('../../images/store/5.png');
}

.storeContainer .productsContainer .productInfo .img-6 {
  background-image: url('../../images/store/6.png');
}

.storeContainer .productsContainer .productInfo .img-7 {
  background-image: url('../../images/store/7.png');
}

.storeContainer .productsContainer .productInfo .img-8 {
  background-image: url('../../images/store/8.png');
}

.storeContainer .productsContainer .productInfo p {
  width: 90%;

  text-align: start;
  font-size: 1vw;
  color: #333;
  text-transform: uppercase;
  line-height: 1vw;

  margin: 10px auto;
}

.storeContainer .productsContainer .productInfo .price {
  text-align: start;
  font-size: 1vw;
  color: #333;
  text-transform: uppercase;
  font-family: var(--font-NeuePlak-Black);
}

/* ============================================== */

@media screen and (max-width: 768px) {
  .storeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .storeContainer .buy {
    margin: 12vw auto auto;
    padding: .5vw 1vw;
    font-size: 5vw;
  }

  .storeContainer .productsContainer {
    width: 90%;
  }

  .storeContainer .productsContainer .productInfo {
    width: 85vw;
    height: 87vw;

    margin: 1vw;
  }

  .storeContainer .productsContainer .productInfo .imgProduct {
    width: 84vw;
    height: calc(84vw / 1.4975);
  }

  .storeContainer .productsContainer .productInfo p {
    width: 90%;
    font-size: 5vw;
    line-height: 5vw;

    margin: 10px auto;
  }

  .storeContainer .productsContainer .productInfo .price {
    font-size: 5vw;
  }
}