* {
  outline: none;
}

.slideContainer {
  position: relative;

  width: 70vw;
  height: calc(70vw);

  margin: 2vw auto 4vw;

  display: flex;
  background-color: #FFF;
}

.slideContainer .slideComponent {
  width: 97%;
  height: 97%;

  margin: auto;
}

.slideContainer .slideComponent .slick-prev {
  position: absolute;
  top: 50%;
  left: -15%;

  z-index: 10;
}

.slideContainer .slideComponent .slick-next {
  position: absolute;
  top: 50%;
  right: -15%;
}

.slideContainer .slideComponent .slick-dots {
  position: absolute;
  bottom: -10%;
}
.slideContainer .slideComponent .slick-dots li.slick-active button:before {
  opacity: .75;
  font-size: 10px;
}

.slideContainer .slideComponent .slick-dots li button:before {
  color: #FFF;
  font-size: 8px;
  opacity: .25;
}

/* ------------------------------------------- */
.storeContainer .slideContainer .productInfoSlide {
  width: 60vw;
  height: 66vw;

  min-width: 150px;
  min-height: 150px;

  background-color: #FFF;

  display: flex;
  flex-flow: column;

  text-decoration: none;
}

.storeContainer .slideContainer .productInfoSlide .imgProductSlide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  width: 65vw;
  height: calc(65vw / 1.4975);

  margin: auto;
}

.storeContainer .slideContainer .productInfoSlide .img-0 {
  background-image: url('../../images/store/0.png');
}

.storeContainer .slideContainer .productInfoSlide .img-1 {
  background-image: url('../../images/store/1.png');
}

.storeContainer .slideContainer .productInfoSlide .img-2 {
  background-image: url('../../images/store/2.png');
}

.storeContainer .slideContainer .productInfoSlide .img-3 {
  background-image: url('../../images/store/3.png');
}

.storeContainer .slideContainer .productInfoSlide .img-4 {
  background-image: url('../../images/store/4.png');
}

.storeContainer .slideContainer .productInfoSlide .img-5 {
  background-image: url('../../images/store/5.png');
}

.storeContainer .slideContainer .productInfoSlide .img-6 {
  background-image: url('../../images/store/6.png');
}

.storeContainer .slideContainer .productInfoSlide .img-7 {
  background-image: url('../../images/store/7.png');
}

.storeContainer .slideContainer .productInfoSlide .img-8 {
  background-image: url('../../images/store/8.png');
}

.storeContainer .slideContainer .productInfoSlide p {
  width: 90%;

  text-align: start;
  font-size: 5vw;
  color: #111;
  text-transform: uppercase;
  line-height: 5vw;

  margin: 1vw auto;
}

.storeContainer .slideContainer .productInfoSlide .price {
  text-align: start;
  font-size: 5vw;
  color: #111;
  text-transform: uppercase;
  font-family: var(--font-NeuePlak-Black);
}