.homeContainer {
  position: absolute;
  top: 5vw;
  left: 0;

  width: 15vw;

  display: flex;
  flex-flow: column;

  z-index: 10;
}

.homeContainer .imgTitle {
  z-index: 2;

  width: 15vw;
  height: calc(15vw / 1.5);

  margin: auto;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/pcPosregistroImg.png');
}

.homeContainer .texts {
  display: none;
}

.homeContainer .texts h2 {
  z-index: 1;

  margin: auto;

  color: #FFF;
  font-size: 1.5vw;
  text-align: center;
}

.homeContainer .texts p {
  z-index: 1;

  margin: auto;

  color: #FFF;
  font-size: 1.2vw;
  text-align: center;
}

@media screen and (max-width: 768px) {

  .homeContainer {
    position: relative;
    top: 0;

    width: 100vw;

    display: flex;
    flex-flow: column;
  }

  .homeContainer .imgBg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../images/pcPosRegistroBackground.png');
  }

  .homeContainer .imgTitle {
    z-index: 2;

    width: 95vw;
    height: calc(95vw / 1.5);

    margin: auto;
    margin-top: 10vh;

    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .homeContainer .texts {
    z-index: 2;

    width: 90vw;

    display: flex;
    flex-flow: column;

    margin: auto;
    margin-bottom: 5vh;
    padding: 2vw;

    background-color: rgba(0, 0, 0, .669);
  }

  .homeContainer .texts h2 {
    z-index: 1;

    margin: auto;

    color: #FFF;
    font-size: 5.5vw;
    text-align: center;
  }

  .homeContainer .texts p {
    z-index: 1;

    margin: auto;

    color: #FFF;
    font-size: 5.2vw;
    text-align: center;
  }
}