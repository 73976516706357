@import '../../../pages/standar.css';

.menuContainer {
  position: fixed;
  top: 0;
  left: 0;

  width: 96vw;

  margin: auto;
  padding: 10px 2vw;

  background-color: rgba(0, 0, 0, 0.699);

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 100;
}

.menuContainer .logo {
  width: 6vw;
  height: calc(6vw / 1.975);

  max-width: 117px;
  max-height: 60px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../images/Logo\ Reebok.png');

  cursor: pointer;
}

.menuContainer .bntMenuHamb {
  display: none;
}

.menuContainer .btnMenuCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuContainer .btnMenuCont .btnMenu {
  position: relative;

  margin: auto 1vw;
  padding: .4vw .7vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;

  cursor: pointer;
}

.menuContainer .btnMenu span {
  height: 2px;

  margin: 0;

  background-color: #FFF;

  border-radius: 2px;
}

.menuContainer .btnMenu .sup {
  position: absolute;
  top: 0;
  left: -2%;

  width: 101%;
}

.menuContainer .btnMenu .rig {
  position: absolute;
  top: 0%;
  right: 2%;

  width: 2px;
  height: 100%;
}

.menuContainer .btnMenu .infer {
  position: absolute;
  bottom: 0;
  left: -2%;

  width: 101%;
}

.menuContainer .btnMenu .left {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 2px;
  height: 100%;
}

.menuContainer .btnMenu .textImg {
  width: 6.5vw;
  height: calc(6vw / 5.0857);

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  font: var(--font-NeuePlak-Bold);
  text-transform: uppercase;
  font-weight: 800;
  color: #333;
  text-align: center;
  line-height: .9vw;
  font-size: .9vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.menuContainer .btnMenu .live {
  background-image: url('../../../images/btn-menu/live-Btn.png');
}
.menuContainer .btnMenu .store {
  background-image: url('../../../images/btn-menu/store-btn.png');
}
.menuContainer .btnMenu .agenda {
  background-image: url('../../../images/btn-menu/agenda-btn.png');
}
.menuContainer .btnMenu .trainers {
  width: 8.8vw;
  height: calc(6.5vw / 5.0857);

  background-image: url('../../../images/btn-menu/trainers-btn.png');
}

.menuContainer .btnMenu .workouts {
  width: 12.5vw;
  height: calc(6.5vw / 5.0857);

  background-image: url('../../../images/btn-menu/works-btn.png');
}

.menuContainer .backBtn {
  width: 2vw;
  height: calc(2vw / 0.975);

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../images/btn-menu/logoutImg.png');

  cursor: pointer;
}


/* ============================================ */

@media screen and (max-width: 768px) {
  .menuContainer .logo {
    width: 20vw;
    height: calc(20vw / 1.975);

    margin: auto;
  }

  .menuContainer .backBtn {
    position: absolute;
    top: 3.5vw;
    right: 3%;

    width: 6.5vw;
    height: calc(6.5vw / 0.975);

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('../../../images/btn-menu/logoutImg.png');

    cursor: pointer;
  }

  .menuContainer .btnMenuCont {
    position: absolute;
    top: 15vw;
    left: 15%;

    padding: 2vw;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    background-color: rgba(0, 0, 0, 0.699);
  }

  .menuContainer .bntMenuHamb-close {
    z-index: 200;
    position: absolute;
    top: 3.5vw;
    left: 3%;
    display: flex;

    width: 6vw;
    height: calc(6vw / 0.975);

    background-image: url('../../../images/btn-menu/hamburguer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .menuContainer .bntMenuHamb-open {
    z-index: 200;
    position: absolute;
    top: 3.5vw;
    left: 3%;
    display: flex;

    width: 6vw;
    height: calc(6vw / 0.975);

    background-image: url('../../../images/btn-menu/closeHmburguer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .menuContainer .btnMenuCont .btnMenu {
    margin: .5vw;
    padding: .4vw .7vw;
  }

  .menuContainer .btnMenu .textImg {
    width: 65.5vw;
    height: calc(65vw / 5.0857);

    font: var(--font-NeuePlak-Bold);
    text-transform: uppercase;
    font-weight: 800;
    color: #333;
    text-align: center;
    line-height: 5vw;
    font-size: 5vw;
  }
}