.switch {
  position: relative;

  width: 60px;
  height: 30px;

  margin: auto;
}

.switch input[type="checkbox"].slideCheck {display:none;}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  content: "";
  position: absolute;
  left: 10%;
  bottom: 18%;

  height: 20px;
  width: 20px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #0B4959;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0B4959;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}